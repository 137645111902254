<template>
  <div>
    <content-template
      :menuName="$t('APMA.finishTask')"
      :backButton="true"
      @backToPage="moveToOther('Detail APMA')"
    >
      <template #form>
        <form>
          <Input
            v-model="edited_item.title"
            :label="$t('general.title')"
            class="s-mb-20"
            :error="validation.title"
          />

          <Input
            v-model="edited_item.youtube_link"
            :label="$t('general.youtubeLink')"
            class="s-mb-20"
          />

          <div class="s-mb-20">
            <label class="s-input-label">Topics</label>
            <Multiselect
              v-model="edited_item.expertise_detail"
              tag-placeholder=""
              :placeholder="`${$t('general.searchOrAdd')} ${$t(
                'sidebar.expertise'
              )}`"
              :options="expertise_dropdown.map((type) => type.id)"
              :custom-label="
                (opt) => expertise_dropdown.find((x) => x.id == opt).name
              "
              :multiple="true"
            ></Multiselect>
            <span class="s-input-error" v-if="validation.expertise_detail">
              {{ $t("general.fillThisField") }}
            </span>
          </div>

          <Textarea
            v-model="edited_item.description"
            :label="$t('general.description')"
            class="s-mb-20"
            :error="validation.description"
          />
          <Textarea
            v-model="edited_item.result"
            :label="$t('APMA.result')"
            class="s-mb-20"
            :error="validation.result"
          />

          <p class="s-text-primary s-weight-600">
            {{ $t("general.uploadImage") }}
          </p>

          <div class="s-pb-30 s-position-relative">
            <div v-if="!fileDoc.name" class="s-file-upload s-text-center">
              <div>
                <img
                  :src="require('@/assets/icons/primary/cloud_upload.png')"
                />
                <p>{{ $t("general.dragAndDrop") }}</p>
                <p>{{ $t("general.or") }}</p>
                <Button>{{ $t("general.uploadFile") }}</Button>
              </div>
            </div>
            <input
              v-if="!fileDoc.name"
              id="document"
              ref="document"
              class="s-hide"
              type="file"
              @change="importData('document')"
              multiple
            />
            <div class="s-position-relative s-fullWidth s-center-flex">
              <div
                class="
                  s-center-flex
                  s-mb-16
                  s-bg-green-40
                  s-plr-10
                  s-radius-10
                  s-fullWidth
                "
                v-if="fileDoc.name"
              >
                <p class="s-text-primary">{{ fileDoc.name }}</p>
                <img
                  :src="require('@/assets/icons/close-primary.svg')"
                  class="s-ml-auto"
                  @click="fileDoc = {}"
                  style="cursor: pointer"
                />
              </div>
            </div>
          </div>
        </form>

        <div class="s-mt-20 s-flex">
          <Button class="s-ml-auto" @click="onFinish" :isLoading="isLoading">{{
            $t("general.submit")
          }}</Button>
        </div>
      </template>
    </content-template>
  </div>
</template>

<script>
import form from "@/mixins/form";
import ContentTemplate from "./contentTemplate.vue";
import { mapActions, mapGetters } from "vuex";
import common from "@/mixins/common";

export default {
  components: { ContentTemplate },
  props: ["inputType", "item"],
  mixins: [form, common],
  data() {
    return {
      fileDoc: {},
      edited_item: {
        result: "",
        file: "",
        description: "",
        title: "",
      },
      validation: {
        result: false,
        file: false,
        description: false,
        title: false,
      },
      isLoading: false,
    };
  },
  mounted() {
    this.initData();
  },
  computed: {
    ...mapGetters({
      expertise_dropdown: "expertise/getExpertise",
      item: "actionplan/getActionPlan",
    }),
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
  },
  methods: {
    ...mapActions({
      setExpertise: "expertise/setExpertiseDropdownData",
      onFinishTask: "actionplan/onFinishTask",
      setActionPlanSingleData: "actionplan/setActionPlanSingleData",
    }),

    async onFinish() {
      this.isLoading = true;
      this.checkEmptyField(
        this.edited_item,
        this.validation,
        true,
        this.fileDoc.name,
        this.inputType
      );
      if (!this.empty_field) {
        var youtube_id = "";
        if (
          this.edited_item.youtube_link != "" ||
          this.edited_item.youtube_link != null
        ) {
          youtube_id = this.generateVideoId(this.edited_item.youtube_link);
        }

        let formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("title", this.edited_item.title);
        formData.append("description", this.edited_item.description);
        formData.append("thumbnail", this.fileDoc);
        if (youtube_id != null) {
          formData.append("youtube_link", youtube_id);
        }
        formData.append("result", this.edited_item.result);
        formData.append("type", this.$route.params.inputType);

        for (var i = 0; i < this.edited_item.expertise_detail.length; i++) {
          formData.append(
            "expertise_detail_id[]",
            this.edited_item.expertise_detail[i]
          );
        }

        await this.onFinishTask(formData);

        if (this.$store.getters["actionplan/getStatus"] == 1) {
          this.$router.push(this.$translate({ name: "APMA" }));
        }
      }
      this.isLoading = true;
    },
    importData(id) {
      const fileInput = this.$refs[id];
      const fileUpload = fileInput.files[0];
      const fsize = fileUpload.size;
      const file = Math.round(fsize / 1024);

      if (file <= 5000) this.fileDoc = fileUpload;
    },
    attachImage(id) {
      document.getElementById(id).click();
    },
    async initData() {
      this.setLoadingPage(true);
      await this.setExpertise();
      await this.setActionPlanSingleData(this.$route.params.id);

      if (this.item) {
        const data = this.item.publish;
        this.edited_item = Object.assign({}, data);
        this.edited_item.result = this.item.result;
        let expertise_temp = new Array();
        data.expertise_detail.forEach((element) => {
          expertise_temp.push(element.id);
        });
        this.edited_item.expertise_detail = Object.assign([], expertise_temp);
        if (this.edited_item.youtube_link !== null) {
          this.edited_item.youtube_link = `https://www.youtube.com/embed/${this.edited_item.youtube_link}`;
        } else {
          this.edited_item.youtube_link = "";
        }
      }
      this.setLoadingPage(false);
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
